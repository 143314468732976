<template>
  <div v-editable="blok">
    <Slider
      :blok="settings"
      class="-mx-2 blog-slider"
      :class="{ 'carousel-buttons-disabled': buttonsDisabled }"
    >
      <slide v-for="(item, i) in items" :key="i" class="px-2">
        <Card
          :headline="item.headline"
          :link="link(item)"
          :image="{ src: item.singleSRC, alt: item.alt }"
          class="mx-2"
        >
          <!-- <div class="feature-bento-box-summary">

            <p>{{ item.summary }}</p>
            <p>{{ formateDate(item.date) }}</p>
          </div> -->
        </Card>
      </slide>
    </Slider>
  </div>
</template>

<script setup>
const props = defineProps({ blok: Object });

const route = useRoute();
const { locale } = useI18n();
const { breakpoint, formateDate } = useUtils();

const link = (item) => {
  return {
    url: `https://media.zweikern.com/${item.alias}`,
    linktype: "url",
    target: "_blank",
  };
};

const items = ref([]);
const response = await useFetch(
  `https://media.zweikern.com/api/v1/blog?limit=${props.blok.limit || 6}&lang=${locale.value}&type=${props.blok.type}`,
).catch((error) => {
  console.log(error.data);
});
items.value = response.data.value.news;

const maxItemsToShow = computed(() => parseInt(props.blok.items_to_show) || 2);
const buttonsDisabled = computed(
  () => items.value?.length <= maxItemsToShow.value,
);

const breakpoints = computed(() => ({
  640: {
    itemsToShow: 2,
    itemsToScroll: 2,
    snapAlign: "start",
  },
  769: {
    itemsToShow: 3,
    itemsToScroll: 3,
    snapAlign: "start",
  },
  1000: {
    itemsToShow: maxItemsToShow.value,
    itemsToScroll: maxItemsToShow.value,
    snapAlign: "start",
  },
}));

const settings = computed(() => ({
  itemsToShow: 1,
  itemsToScroll: 1,
  snapAlign: "start",
  navigation: true,
  pagination: false,
  breakpoints: breakpoints.value,
}));
</script>
