<template>
  <div
    class="feature-bento-box h-full text-left rounded-lg overflow-hidden cursor-pointer"
    @click="onClick"
  >
    <img v-bind="image" class="inline-block rounded rounded-lg" />

    <div class="">
      <MyLink v-if="link" :link="link" class="no-underline text-black">
        <h3 class="feature-bento-box-title my-4" :class="{ 'mb-0': !teaser }">
          {{ headline }}
        </h3>
      </MyLink>
      <h3 v-else class="feature-bento-box-title" :class="{ 'mb-0': !teaser }">
        {{ headline }}
      </h3>
      <div
        v-if="teaser"
        class="feature-bento-box-summary"
        v-html="teaser"
      ></div>
      <slot />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  image: {
    type: Object,
    required: true,
  },
  headline: {
    type: String,
    required: true,
  },
  teaser: {
    type: String,
    required: true,
  },
  link: {
    type: String,
    required: true,
  },
});

const utils = useUtils();

const onClick = () => {
  if (utils.getUrlFromLink(props.link)) {
    utils.goto(`${utils.getUrlFromLink(props.link)}`);
  }
};
</script>

<style scoped>
.card-wrapper > * {
  color: #000;
}
</style>
